<template>
  <div class="orderPage">
    <router-view name="order"/>
  </div>
</template>

<script>
  import OrderJs from './Order.js'

  export default OrderJs
</script>

<style lang="less" scoped>
  .orderPage {
    padding: 30px;

  }
</style>
