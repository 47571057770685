
export default {
  data () {
    return {
      tableData: [],
      pagination:{}
    }
  },
  mounted () {

  },
  methods: {
  }
}
